import { Outlet, useLocation } from "react-router-dom";
import { ReactNode } from "react";
import { Player } from "./Player";
import { PlaylistModal } from "./PlaylistModal";
import styles from "./styles/Layout.module.css";

const Ads: ReactNode[] = [
    <a
        key={"wellobe"}
        href="https://go.adt253.net/t/t?a=1956478875&as=1628518118&t=2&tk=1"
        target="_blank"
        rel="noopener noreferrer">
        <img
            src="https://track.adtraction.com/t/t?a=1956478875&as=1628518118&t=1&tk=1&i=1"
            width="700"
            height="500"
            alt={"wellobe ad"}
        />
    </a>,
    <a
        key={"vuxense"}
        href="https://go.vuxen.se/t/t?a=1833770315&as=1628518118&t=2&tk=1"
        target="_blank"
        rel="noopener noreferrer">
        <img
            src="https://track.adtraction.com/t/t?a=1833770315&as=1628518118&t=1&tk=1&i=1"
            width="728"
            height="90"
            alt={"vuxen.se ad"}
        />
    </a>,
];

export const Layout = () => {
    const { pathname } = useLocation();
    const adIndex = Math.floor(Math.random() * Ads.length);
    return (
        <div className={"main " + styles.main}>
            <div className="container">
                <div className={styles.adsense} key={`adsense-${pathname}`}>
                    {Ads[adIndex]}
                </div>
                <Outlet />
            </div>
            <Player key="7" />
            <PlaylistModal />
        </div>
    );
};
